import { BrandConfig, BrandData, Colors, Constants, Content } from "../../types";

const brandData: BrandData = {
  id: 7,
  title: "Big Bob's Bakers",
};

const colors: Colors = {
  lightMain: "rgba(246, 188, 27, 0.25)",
  main: "#F3722D",
  white: "#fff",
  darkGrey: "#1d262f",
  primaryButton: {
    main: "#D7331B",
    hover: "#C02B16",
    color: "white",
  },
  primaryContainedButton: {
    main: "white",
    hover: "#D7331B",
    color: "rgba(29, 38, 47, 1)",
    colorHover: "white",
    borderColor: "#D7331B",
  },
  secondaryButton: {
    main: "#EEE6DB",
    hover: "#D8CEC1",
    color: "rgba(29, 38, 47, 1)",
  },
};

const content: Content = {
  aboutUs: {
    subtitle: "",
    text: "You know makin’ good baked taters ain’t rocket surgery, but at  Big Bob’s Bakers we still take turnin’ out the perfect stuffed spud real serious.\nWhether it’s our OG stuffed baked potatoes, known as ‘the Original Bakers’ or our ‘Ultimate Bakers’  that take it up a notch, these here baked potatoes get the job done and then some. \nTry the Original Brisket Giant Baker - a roasted baked potato stuffed with chopped BBQ brisket, then topped with butter, sour cream, cheddar cheese, chives and served with BBQ sauce on the side. Good enough to make you throw a hissy fit. \nBut the baker that Big Bob’s really takes a shine to is the Ultimate Chicken Ranch Baker, a giant baked potato stuffed with smoked chicken, bacon, mac & cheese, jalapeño cheddar sausage, layered on your spud with butter, sour cream, cheese, chives and a side of BBQ sauce. \nNow at Big Bob’s Bakers, we don’t claim to be the sharpest tools in the shed, but we do know how to deliver the best dang giant stuffed bakers around. Order up. ",
    title: " Big Bob’s Bakers, a Baked Potato Shack",
  },
  hero: {
    title: "Big Bob’s Bakers",
    subtitle: "A baked potato shack",
    orderNowText: "Order Now",
    viewMenuText: "View Menu",
  },
  meta: {
    title: "Big Bob's Bakers",
    description:
      "Welcome to Big Bob’s Bakers, where we elevate the humble potato to an art form. Our journey began with a simple love for comforting, loaded potatoes—a love we've turned into a flavorful mission. Each spud is carefully chosen, loaded with premium toppings, and baked to indulgent perfection. We pride ourselves on blending traditional goodness with innovative twists, offering a delightful symphony of flavors that tantalize your taste buds. At Big Bob’s Bakers, every loaded potato is not just a meal; it's a hearty experience crafted with passion and served with a warm smile.",
    image: "",
    url: "",
  },
};

const constants: Constants = {
  menuAtAGlancePdf: "brand_Menu_At_Glance.pdf",
  privacyPolicyPdf: "BigBob_Privacy_policy.pdf",
  termsAndConditionsPdf: "BigBob_T&C.pdf",
};

const brandConfig: BrandConfig = {
  brandData,
  constants,
  content,
  colors,
};

export default brandConfig;
